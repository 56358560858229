import { createContext } from 'react';
import { Stripe } from '@stripe/stripe-js';
import firebase from 'firebase/app';

import API from './api/index';

type AuthContext = {
  signIn(credentials: any): Promise<void>;
  signOut(): Promise<void>;
  api: API;
  stripe?: Stripe | null;
  user?: firebase.User | null;
  isDevBuild: boolean;
  role?: string;
};

// Create a new context and export it
export default createContext<AuthContext | null>(null);
