import { ReactElement, useState, useEffect, useRef } from 'react';

// Type definitions for twilio video
import {
  LocalParticipant,
  RemoteParticipant,
  VideoTrackPublication,
  AudioTrackPublication,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteVideoTrack,
  RemoteAudioTrack
} from 'twilio-video';

// Material UI animations
import {
  Typography,
  Avatar,
  Paper,
  Grow,
  CircularProgress
} from '@material-ui/core';

// Material UI Icons
import { MicOffRounded } from '@mui/icons-material';
import { Fullscreen } from '@mui/icons-material';
import { FullscreenExit } from '@mui/icons-material';
// Shared helpers
import { getInitials } from 'helpers';

// Style
import useStyles from './styles';
import useWindowSize from 'hooks/useWindowSize';
import {
  // GaussianBlurBackgroundProcessor,
  // Pipeline,
  // isSupported,
  VirtualBackgroundProcessor,
  ImageFit
} from '@twilio/video-processors'; // Import Twilio background processor

type Track =
  | LocalVideoTrack
  | RemoteVideoTrack
  | LocalAudioTrack
  | RemoteAudioTrack
  | null;

const trackpubsToTracks = (
  trackMap: Map<string, VideoTrackPublication | AudioTrackPublication>
) =>
  Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);

export const Participant = ({
  participant,
  muted,
  isLocal,
  virtualBackgroundProcessor,
  setVirtualBackgroundProcessor,
  virtualBackgroundApplied,
  setVirtualBackgroundApplied
}: {
  participant: LocalParticipant | RemoteParticipant;
  muted: boolean;
  isLocal?: boolean;
  virtualBackgroundProcessor: VirtualBackgroundProcessor | null;
  setVirtualBackgroundProcessor: (
    processor: VirtualBackgroundProcessor | null
  ) => void;
  virtualBackgroundApplied: boolean;
  setVirtualBackgroundApplied: (applied: boolean) => void;
}): ReactElement => {
  const initialVideoTracks = trackpubsToTracks(participant.videoTracks);
  const initialAudioTracks = trackpubsToTracks(participant.audioTracks);

  const [remoteMuted, setRemoteMuted] = useState<boolean>(
    !initialAudioTracks[0]?.isEnabled
  );
  const [remoteCamera, setRemoteCamera] = useState<boolean>(
    Boolean(initialVideoTracks[0]?.isEnabled)
  );
  const [reconnecting, setReconnecting] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [videoTracks, setVideoTracks] = useState<Track[]>(initialVideoTracks);
  const [audioTracks, setAudioTracks] = useState<Track[]>(initialAudioTracks);
  const [, participantName] = JSON.parse(participant.identity);
  // const [backgroundBlurProcessor, setBackgroundBlurProcessor] =
  //   useState<GaussianBlurBackgroundProcessor | null>(null);
  // const [blurApplied, setBlurApplied] = useState<boolean>(false); // State to manage blur effect application

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  // Generate component styles
  const classes = useStyles();
  const [width] = useWindowSize();

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const addTrack = (track: Track) => {
      if (track && track.kind === 'video' && !track.name.includes('screen')) {
        setRemoteCamera(track.isEnabled);
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track && track.kind === 'audio') {
        setRemoteMuted(!track.isEnabled);
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const removeTrack = (track: Track) => {
      if (track && track.kind === 'video' && !track.name.includes('screen')) {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track && track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    if (isLocal) {
      // (participant as LocalParticipant).on('trackUnpublished', removeTrack);
    }
    // (participant as RemoteParticipant).on('trackUnpublished', removeTrack);
    // (participant as RemoteParticipant).on('trackPublished', addTrack);
    (participant as RemoteParticipant).on('trackSubscribed', addTrack);
    (participant as RemoteParticipant).on('trackUnsubscribed', removeTrack);
    // [
    //   'disconnected',
    //   'trackDimensionsChanged',
    //   'trackDisabled',
    //   'trackEnabled',
    //   'trackMessage',
    //   'trackPublished',
    //   'trackPublishPriorityChanged',
    //   'trackStarted',
    //   'trackSubscribed',
    //   'trackSubscriptionFailed',
    //   'trackSwitchedOff',
    //   'trackSwitchedOn',
    //   'trackUnpublished',
    //   'trackUnsubscribed',
    // ].forEach((event) =>
    //   (participant as RemoteParticipant).on(event, () => console.log(`${participant.identity} REMOTE ${event}`)),
    // );
    [
      'disconnected',
      'trackDimensionsChanged',
      'trackDisabled',
      'trackEnabled',
      'trackPublicationFailed',
      'trackPublished',
      'trackStarted',
      'trackStopped'
    ].forEach((event) =>
      (participant as LocalParticipant).on(event, () =>
        console.log(`${participant.identity} LOCAL ${event}`)
      )
    );
    participant.on('reconnecting', () => setReconnecting(true));
    participant.on('reconnected', () => setReconnecting(false));

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      // participant.removeAllListeners();
    };
  }, [participant, isLocal]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoRef.current && !videoTrack.name.includes('screen')) {
      videoTrack.on('disabled', () => setRemoteCamera(false));
      videoTrack.on('enabled', () => setRemoteCamera(true));
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioRef.current) {
      audioTrack.on('disabled', () => setRemoteMuted(true));
      audioTrack.on('enabled', () => setRemoteMuted(false));
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  // const initializeBackgroundBlurProcessor = (
  //   videoTrack: LocalVideoTrack | RemoteVideoTrack
  // ) => {
  //   if (isSupported) {
  //     const bg = new GaussianBlurBackgroundProcessor({
  //       assetsPath: '/',
  //       maskBlurRadius: 10,
  //       blurFilterRadius: 5,
  //       pipeline: Pipeline.Canvas2D,
  //       debounce: true
  //     });

  //     bg.loadModel()
  //       .then(() => {
  //         videoTrack.addProcessor(bg, {
  //           inputFrameBufferType: 'offscreencanvas'
  //           // outputFrameBufferContextType: 'webgl2'
  //         });
  //         setBackgroundBlurProcessor(bg);
  //         setBlurApplied(true); // Optionally set blurApplied to true upon successful initialization
  //       })
  //       .catch((error) => {
  //         console.error('Error initializing background blur processor:', error);
  //       });
  //   } else {
  //     console.log('Background blur not supported.');
  //   }
  // };

  // const applyBlurToTrack = (videoTrack: LocalVideoTrack | RemoteVideoTrack) => {
  //   if (backgroundBlurProcessor) {
  //     videoTrack.addProcessor(backgroundBlurProcessor, {
  //       inputFrameBufferType: 'offscreencanvas'
  //       // outputFrameBufferContextType: 'webgl2'
  //     });
  //     setBlurApplied(true);
  //   }
  // };

  // const removeBlurFromTrack = (
  //   videoTrack: LocalVideoTrack | RemoteVideoTrack
  // ) => {
  //   if (backgroundBlurProcessor) {
  //     videoTrack.removeProcessor(backgroundBlurProcessor);
  //     setBlurApplied(false);
  //   }
  // };

  // const handleBlur = () => {
  //   const videoTrack = videoTracks[0];
  //   if (videoTrack && videoTrack.kind === 'video') {
  //     if (!blurApplied) {
  //       if (virtualBackgroundApplied) {
  //         removeVirtualBackgroundFromTrack(videoTrack);
  //       }
  //       if (!backgroundBlurProcessor) {
  //         initializeBackgroundBlurProcessor(videoTrack);
  //       }
  //       applyBlurToTrack(videoTrack);
  //     } else {
  //       removeBlurFromTrack(videoTrack);
  //     }
  //   }
  // };

  const initializeVirtualBackgroundProcessor = (
    videoTrack: LocalVideoTrack | RemoteVideoTrack,
    backgroundUrl: string
  ) => {
    const img = new Image();

    img.onload = () => {
      const newProcessor = new VirtualBackgroundProcessor({
        assetsPath: '/',
        backgroundImage: img,
        personProbabilityThreshold: 0.6, // Adjust based on your application's needs
        fitType: ImageFit.Contain,
        useWasm: true
      });

      newProcessor
        .loadModel()
        .then(() => {
          if (virtualBackgroundProcessor) {
            videoTrack.removeProcessor(virtualBackgroundProcessor);
          }
          videoTrack.addProcessor(newProcessor, {
            // inputFrameBufferType: 'video',
            // outputFrameBufferContextType: 'offscreencanvas'
          });
          setVirtualBackgroundProcessor(newProcessor);
          setVirtualBackgroundApplied(true);
        })
        .catch((error) => {
          console.error(
            'Error initializing virtual background processor:',
            error
          );
        });
    };

    img.onerror = (error) => {
      console.error('Error loading background image:', error);
      // Handle error loading image, maybe fallback to default or show an error message
    };

    img.src = backgroundUrl;
    img.crossOrigin = 'anonymous';
    img.style.objectFit = 'cover';
    img.style.backgroundPosition = 'center';
  };

  // const applyVirtualBackgroundToTrack = (
  //   videoTrack: LocalVideoTrack | RemoteVideoTrack
  // ) => {
  //   if (virtualBackgroundProcessor) {
  //     videoTrack.addProcessor(virtualBackgroundProcessor, {
  //       // inputFrameBufferType: 'video',
  //       // outputFrameBufferContextType: 'offscreencanvas'
  //     });
  //     setVirtualBackgroundApplied(true);
  //   }
  // };

  const removeVirtualBackgroundFromTrack = (
    videoTrack: LocalVideoTrack | RemoteVideoTrack
  ) => {
    if (virtualBackgroundProcessor) {
      videoTrack.removeProcessor(virtualBackgroundProcessor);
      setVirtualBackgroundProcessor(null); // Clear the processor reference
      setVirtualBackgroundApplied(false);
    }
  };

  const handleVirtualBackground = (backgroundUrl: string) => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoTrack.kind === 'video') {
      // Check if the clicked background is already applied
      if (virtualBackgroundApplied && virtualBackgroundProcessor) {
        if (virtualBackgroundProcessor.backgroundImage.src === backgroundUrl) {
          // If current background image is the same as clicked, remove the processor
          removeVirtualBackgroundFromTrack(videoTrack);
          return; // Exit early since the background is removed
        }
        // } else {
        //   // Remove existing virtual background if different background is clicked
        //   // removeVirtualBackgroundFromTrack(videoTrack);
        // }
      }
      // Remove blur if applied
      // if (blurApplied) {
      //   removeBlurFromTrack(videoTrack);
      // }

      // Initialize and apply the new virtual background processor
      initializeVirtualBackgroundProcessor(videoTrack, backgroundUrl);

      // applyVirtualBackgroundToTrack(videoTrack);
    }
  };

  // Add a condition to check if the window width is between 500 and 900
  const marginTopStyle =
    width >= 500 && width <= 900 ? { marginTop: '10px', padding: '8px' } : {};

  const marginTop = width >= 500 && width <= 900 ? { marginTop: '20px' } : {};

  const widthandHeightStyle =
    width >= 500 && width <= 900 ? { width: '30px', height: '30px' } : {};

  const widthandHeightofIconStyle =
    width >= 500 && width <= 900 ? { width: '20px', height: '20px' } : {};

  const virtualBackgrounds = [
    {
      dlink: `https://firebasestorage.googleapis.com/v0/b/eng-tutor-app.appspot.com/o/photos%2Fe-english_background_light.png?alt=media&token=772239af-2de0-4bb6-85ce-d01f7a13ec79`
    },
    {
      dlink: `https://firebasestorage.googleapis.com/v0/b/eng-tutor-app.appspot.com/o/photos%2Fe-english_background_dark.png?alt=media&token=ae94efea-966a-46d7-b7a1-26e47c9d118b`
    }
  ];

  // const blurBackground = [
  //   {
  //     dlink:
  //       'https://firebasestorage.googleapis.com/v0/b/eng-tutor-app-dev.appspot.com/o/photos%2Fblur.png?alt=media&token=336eef93-a9a8-43b7-9e81-e4458e25f797'
  //   }
  // ];
  return (
    <>
      <Paper
        className={
          width > 900
            ? classes.videoRoot
            : width >= 500 && width <= 900
            ? classes.videoRootLandscape
            : classes.videoRootResponsive
        }
        style={{
          width: isFullScreen
            ? width < 500
              ? 280
              : width >= 500 && width <= 900
              ? 250 // Set the width for full-screen between 500 and 900
              : 310
            : width < 500
            ? 230
            : width >= 500 && width <= 900
            ? 250
            : 310,
          height: isFullScreen
            ? width < 500
              ? 158
              : width >= 500 && width <= 900
              ? 130 // Set the height for full-screen between 500 and 900
              : 175
            : width < 500
            ? 130
            : width >= 500 && width <= 900
            ? 58
            : 175
        }}
        elevation={1}>
        <video
          hidden={!remoteCamera || reconnecting}
          className={classes.video}
          ref={videoRef}
          autoPlay={true}
        />
        <div
          style={{ display: remoteCamera && !reconnecting ? 'none' : 'flex' }}
          className={
            width > 500 && width < 900
              ? classes.loaderLandscape
              : classes.loader
          }>
          {/* <Avatar className={classes.avatar}> */}
          {/* {reconnecting ? <CircularProgress /> : getInitials(participantName)} */}
          {/* </Avatar>  */}
          {!(width > 500 && width < 900) && (
            <Avatar className={classes.avatar}>
              {reconnecting ? (
                <CircularProgress />
              ) : (
                getInitials(participantName)
              )}
            </Avatar>
          )}
          <Typography
            variant={width > 900 ? 'h4' : 'h6'}
            style={marginTop}
            color="textPrimary">
            {participantName}
          </Typography>
          {reconnecting && (
            <Typography variant="caption" color="textSecondary">
              Reconnecting
            </Typography>
          )}
        </div>
        <audio ref={audioRef} autoPlay={true} muted={muted} />
        <Typography
          variant={width > 900 ? 'h4' : 'h6'}
          style={{
            display: !remoteCamera || reconnecting ? 'none' : 'flex',
            position: 'absolute',
            zIndex: '999',
            bottom: '0',
            left: '50%',
            color: 'white',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            width: 'max-content'
          }}>
          {participantName}
        </Typography>
        <div className={classes.content} style={marginTopStyle}>
          <Grow in={remoteMuted}>
            <div className={classes.indicator} style={widthandHeightStyle}>
              <MicOffRounded style={widthandHeightofIconStyle} />
            </div>
          </Grow>
        </div>
        {width < 900 && (
          <div className={classes.contentFullScreen} style={marginTopStyle}>
            <div
              className={classes.indicatorFullScreen}
              style={widthandHeightStyle}
              onClick={() => setIsFullScreen(!isFullScreen)}>
              {isFullScreen ? (
                <FullscreenExit style={widthandHeightofIconStyle} />
              ) : (
                <Fullscreen style={widthandHeightofIconStyle} />
              )}
            </div>
          </div>
        )}
      </Paper>
      {isLocal && (
        <div
          style={{
            padding: '10px',
            display: 'flex',
            gap: '10px'
          }}>
          {/* {blurBackground.map((background, index) => (
            <img
              key={index}
              onClick={() => handleBlur()}
              src={background.dlink}
              alt={`Background ${index + 1}`}
              style={{
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                cursor: 'pointer'
              }}
            />
          ))} */}

          {virtualBackgrounds.map((background, index) => (
            <img
              key={index}
              onClick={() => handleVirtualBackground(background.dlink)}
              src={background.dlink}
              alt={`Background ${index + 1}`}
              style={{
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                cursor: 'pointer'
                // objectFit: 'fill'
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};
