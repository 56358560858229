import { ReactNode } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';
import { OptionsObject } from 'notistack';

type SnackbarFunc = (
  message: ReactNode,
  options?: OptionsObject | undefined
) => string | number;

interface Material {
  id?: string;
  description: string;
  icon: string;
  visible: boolean;
}

interface Lesson {
  code: string;
  id: string;
  level: number;
  name: string;
  pos: number;
  studentResource: string;
  tutorResource: string;
}

interface Course {
  id?: string;
  abbreviation: string;
  description: string;
  icon: string;
  lessons: Lesson[];
}

interface APIMaterials {
  /**
   * API endpoint for opening a lesson document
   */
  openLessonDocument: (
    enqueueSnackbar: SnackbarFunc,
    lesson: Lesson,
    role: 'tutor' | 'student'
  ) => Promise<void>;
  /**
   * API endpoint for getting a lesson document URL
   */
  getLessonDocument: (
    enqueueSnackbar: SnackbarFunc,
    path: string
  ) => Promise<string>;
  /**
   * API endpoint for retriving a list of materials
   */
  getMaterials: (store?: boolean) => Promise<Material[]>;
  /**
   * API endpoint for retriving courses within a material
   */
  getCourses: (material: string, store?: boolean) => Promise<Course[]>;
  /**
   * ADMIN API endpoint for retriving a list of all materials (including hidden)
   */
  adminGetAllMaterials: (store?: boolean) => Promise<Material[]>;
  /**
   * ADMIN API endpoint for creating / updating a material
   */
  adminSetMaterial: (id: string, data: Material) => Promise<void>;
  /**
   * ADMIN API endpoint for deleting a material
   */
  adminDeleteMaterial: (id: string) => Promise<void>;
  /**
   * ADMIN API endpoint for creating / updating a course
   */
  adminSetCourse: (material: string, id: string, data: Course) => Promise<void>;
  /**
   * ADMIN API endpoint for deleting a course
   */
  adminDeleteCourse: (material: string, id: string) => Promise<void>;
  /**
   * API endpoint for adding a lesson
   */
  adminAddLesson: (
    material: string,
    course: string,
    data: Lesson
  ) => Promise<void>;
  /**
   * ADMIN API endpoint for editing a lesson
   */
  adminEditLesson: (
    material: string,
    course: string,
    lessons: Lesson[],
    data: Lesson
  ) => Promise<void>;
  /**
   * ADMIN API endpoint for deleting a lesson
   */
  adminDeleteLesson: (
    material: string,
    course: string,
    lessons: Lesson[],
    data: Lesson
  ) => Promise<void>;
  /**
   * ADMIN API endpoint for listing the files associated with a course
   */
  adminListCourseFiles: (
    material: string,
    course: string,
    role: string
  ) => Promise<string[]>;
}

function Materials(self: API): APIMaterials {
  const openLessonDocument = async (
    enqueueSnackbar: SnackbarFunc,
    lesson: Lesson,
    role: 'tutor' | 'student'
  ) => {
    // Show an opening file snackbar
    enqueueSnackbar(`Opening material for '${lesson.name}'...`);

    const resource =
      role === 'tutor' ? lesson.tutorResource : lesson.studentResource;

    if (self.store.get(resource)) {
      // Open the stored download URL
      // eslint-disable-next-line no-undef
      window.open(self.store.get(resource), '_blank');
    } else {
      try {
        // Get the download URL
        const downloadURL = await firebase
          .storage()
          .ref(resource)
          .getDownloadURL();

        // Save the download URL in the store
        self.store.set(resource, downloadURL);

        // Open the file in a new window
        // eslint-disable-next-line no-undef
        window.open(downloadURL, '_blank');
      } catch (error: any) {
        // Show an error snackbar
        enqueueSnackbar(error.message, { variant: 'error' });

        // Log out the error to the console
        console.error(error);
      }
    }
  };

  const getLessonDocument = async (
    enqueueSnackbar: SnackbarFunc,
    path: string
  ) => {
    if (self.store.get(path)) {
      // Return the stored download URL
      // eslint-disable-next-line no-undef
      return self.store.get(path);
    } else {
      try {
        // Get the download URL
        const downloadURL = await firebase.storage().ref(path).getDownloadURL();

        // Save the download URL in the store

        self.store.set(path, downloadURL);

        // Open the file in a new window
        // eslint-disable-next-line no-undef
        return downloadURL;
      } catch (error: any) {
        // Show an error snackbar
        enqueueSnackbar(error.message, { variant: 'error' });

        // Log out the error to the console
        console.error(error);
      }
    }
  };

  const getMaterials = async (store = true): Promise<Material[]> => {
    return self._firebase<Material[]>(
      'materialsGetMaterials',
      async () => {
        return (
          await firebase
            .firestore()
            .collection('materials')
            .where('visible', '==', true)
            .get()
        ).docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
      },
      store
    );
  };

  const getCourses = async (
    material: string,
    store = true
  ): Promise<Course[]> => {
    return self._firebase<Course[]>(
      `materialsGetCourses-${material}`,
      async () => {
        return (
          await firebase
            .firestore()
            .collection(`materials/${material}/courses`)
            .get()
        ).docs.map((course) => {
          const data: Course = course.data() as Course;
          return {
            id: course.id,
            ...data,
            lessons: data.lessons
              ? data.lessons
                  .sort(
                    (a, b) => a.level * 100 + a.pos - (b.level * 100 + b.pos)
                  )
                  .map((lesson) => ({
                    ...lesson,
                    code: `${data.abbreviation}${
                      lesson.level * 100 + lesson.pos
                    }`
                  }))
              : []
          };
        });
      },
      store
    );
  };

  // --- ADMIN API ENDPOINTS ---
  const adminGetAllMaterials = async (store = true): Promise<Material[]> => {
    self.enforceRole();

    return self._firebase<Material[]>(
      'materialsAdminGetAllMaterials',
      async () => {
        return (
          await firebase.firestore().collection('materials').get()
        ).docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
      },
      store
    );
  };

  const adminSetMaterial = async (
    id: string,
    data: Material
  ): Promise<void> => {
    self.enforceRole();

    // Update the material document
    await firebase.firestore().doc(`materials/${id}`).set(data);

    // Update the store
    const storeMaterials = self.store.get(
      'firebase-materialsAdminGetAllMaterials'
    );
    if (
      storeMaterials.findIndex((material: Material) => material.id === id) ===
      -1
    ) {
      self.store.set('firebase-materialsAdminGetAllMaterials', [
        ...storeMaterials,
        { id, ...data }
      ]);
    } else {
      self.store.set(
        'firebase-materialsAdminGetAllMaterials',
        storeMaterials.map((material: Material) =>
          material.id === id ? { id, ...data } : material
        )
      );
    }
  };

  const adminDeleteMaterial = async (id: string): Promise<void> => {
    self.enforceRole();

    // Delete the material document
    // await firebase.storage().ref(`materials/${id}`).delete();
    await firebase.firestore().doc(`materials/${id}`).delete();

    // Update the store
    const storeMaterials = self.store.get(
      'firebase-materialsAdminGetAllMaterials'
    );
    self.store.set(
      'firebase-materialsAdminGetAllMaterials',
      storeMaterials.filter((material: Material) => material.id !== id)
    );
  };

  const adminSetCourse = async (
    material: string,
    id: string,
    data: Course
  ): Promise<void> => {
    self.enforceRole();

    // Update the course document
    return firebase
      .firestore()
      .doc(`materials/${material}/courses/${id}`)
      .set(
        {
          ...data
        },
        { merge: true }
      );
  };

  const adminDeleteCourse = async (
    material: string,
    id: string
  ): Promise<void> => {
    self.enforceRole();

    // Delete the lesson document
    // await firebase.storage().ref(`materials/${material}/${course}`).delete();
    return firebase
      .firestore()
      .doc(`materials/${material}/courses/${id}`)
      .delete();
  };

  const adminAddLesson = async (
    material: string,
    course: string,
    data: Lesson
  ): Promise<void> => {
    self.enforceRole();

    // Update the lessons array
    return firebase
      .firestore()
      .doc(`materials/${material}/courses/${course}`)
      .update({
        lessons: firebase.firestore.FieldValue.arrayUnion(data)
      });
  };

  const adminEditLesson = async (
    material: string,
    course: string,
    lessons: Lesson[],
    data: Lesson
  ): Promise<void> => {
    self.enforceRole();

    // Update the lessons array
    return firebase
      .firestore()
      .doc(`materials/${material}/courses/${course}`)
      .update({
        lessons: lessons.map((lesson) => {
          return lesson.id === data.id ? data : lesson;
        })
      });
  };

  const adminDeleteLesson = async (
    material: string,
    course: string,
    lessons: Lesson[],
    data: Lesson
  ): Promise<void> => {
    self.enforceRole();

    // Delete the lesson from the lessons array
    return firebase
      .firestore()
      .doc(`materials/${material}/courses/${course}`)
      .update({
        lessons: lessons.filter((lesson) => lesson.id !== data.id)
      });
  };

  const adminListCourseFiles = async (
    material: string,
    course: string,
    role: string
  ): Promise<string[]> => {
    self.enforceRole();

    const list = await firebase
      .storage()
      .ref(`materials/${material}/${course}/${role}`)
      .listAll();

    // Delete the lesson from the lessons array
    return list.items.map((file) => file.fullPath);
  };

  // Return the endpoint functions
  return {
    openLessonDocument,
    getLessonDocument,
    getMaterials,
    getCourses,
    // ADMIN ENDPOINTS
    adminGetAllMaterials,
    adminSetMaterial,
    adminDeleteMaterial,
    adminSetCourse,
    adminDeleteCourse,
    adminAddLesson,
    adminEditLesson,
    adminDeleteLesson,
    adminListCourseFiles
  };
}

export default Materials;
